import './ArticleEditPage.css';
import { get } from '../../utils/API';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactMarkdown from 'react-markdown';
import { AppLayout, BreadcrumbGroup, Container, Form, FormField, Grid, Input, Spinner, Textarea } from '@awsui/components-react';
import Nav from '../../common/Nav';
import { toSnakeCase } from '../../utils/Utils';

export default function ArticleEditPage() {
  // variables
  const [article, setArticle] = useState({text:{}});
  const [loading, setLoading] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    const getArticle = async () => {
      let res = await get({
        path: 'articles/test_article',
      });

      setArticle(res);
      setLoading(false);
    }

    getArticle();
  }, []);

  return (
    <AppLayout
      headerSelector='.App-header'
      toolsHide={true}
      navigation={<Nav/>}
      navigationOpen={navOpen}
      onNavigationChange={(event) => { setNavOpen(!navOpen) }}
      contentHeader={
        <BreadcrumbGroup
          items={[
            {text:'Articles', href: '#/articles'},
            {text:article.title || id, href: `#/article/${id}`},
            {text:'Edit', href: `#/article/${id}/edit`},
          ]}
        />
      }
      content={
        <>
        {
          loading ?
            <Spinner size='large' />
            :
            <Form
            >
              <Grid
                gridDefinition={[
                  {colspan:12},
                  {colspan:12},
                ]}
              >
                <Container
                  header='Metadata'
                >
                  <Grid
                    gridDefinition={[
                      {colspan:6},
                      {colspan:6},
                      {colspan:12},
                    ]}
                  >
                    <FormField
                      label='Title'
                      stretch={true}
                    >
                      <Input
                        value={article.title}
                        onChange={(event) => { setArticle({...article, title: event.detail.value}) }}
                      />
                    </FormField>
                    <FormField
                      label='ID'
                      stretch={true}
                    >
                      {toSnakeCase(article.title || '')}
                    </FormField>
                    <FormField
                      label='Source'
                      stretch={true}
                    >
                      <Input
                        value={article.source}
                        onChange={(event) => { setArticle({...article, source: event.detail.value}) }}
                      />
                    </FormField>
                  </Grid>
                </Container>
                <Container
                  header='Text'
                >
                  <Grid
                    gridDefinition={[
                      {colspan: 12},
                      {colspan: 12},
                      {colspan: 12},
                    ]}
                  >
                    <FormField
                      label='Low complexity'
                      stretch={true}
                    >
                      <Grid
                        className='markdown-preview-grid'
                        gridDefinition={[
                          {colspan: 6},
                          {colspan: 6}
                        ]}
                      >
                        <Textarea
                          className='markdown-editor'
                          value={article.text.low}
                          placeholder='Your text here'
                          onChange={(event) => { setArticle({...article, text:{...article.text, low: event.detail.value}})}}
                        />
                        <ReactMarkdown className='markdown-preview'>{article.text.low || '*Your text Here*'}</ReactMarkdown>
                      </Grid>
                    </FormField>
                    <FormField
                      label='Medium complexity'
                      stretch={true}
                    >
                      <Grid
                        className='markdown-preview-grid'
                        gridDefinition={[
                          {colspan: 6},
                          {colspan: 6}
                        ]}
                      >
                        <Textarea
                          className='markdown-editor'
                          value={article.text.med}
                          placeholder='Your text here'
                          onChange={(event) => { setArticle({...article, text:{...article.text, med: event.detail.value}})}}
                        />
                        <ReactMarkdown className='markdown-preview'>{article.text.med || '*Your text Here*'}</ReactMarkdown>
                      </Grid>
                    </FormField>
                    <FormField
                      label='High complexity'
                      stretch={true}
                    >
                      <Grid
                        className='markdown-preview-grid'
                        gridDefinition={[
                          {colspan: 6},
                          {colspan: 6}
                        ]}
                      >
                        <Textarea
                          className='markdown-editor'
                          value={article.text.high}
                          placeholder='Your text here'
                          onChange={(event) => { setArticle({...article, text:{...article.text, high: event.detail.value}})}}
                        />
                        <ReactMarkdown className='markdown-preview'>{article.text.high || '*Your text Here*'}</ReactMarkdown>
                      </Grid>
                    </FormField>
                  </Grid>
                </Container>
              </Grid>
            </Form>
        }
        </>
      }
    />
  );
}
