import './AdminPage.css';
import { get } from '../utils/API';
import { useState } from 'react';
import { AppLayout, Container, Form, FormField, Grid, Input, Textarea } from '@awsui/components-react';
import Nav from '../common/Nav';
import ReactMarkdown from 'react-markdown';

export default function AdminPage() {
  // variables
  const [article, setArticle] = useState({text:{}});
  const [navOpen, setNavOpen] = useState(false);

  return (
    <AppLayout
      headerSelector='.App-header'
      toolsHide={true}
      navigation={<Nav/>}
      navigationOpen={navOpen}
      onNavigationChange={(event) => { setNavOpen(!navOpen) }}
      content={
        <Grid
          gridDefinition={[
            {colspan:12},
            {colspan:12},
          ]}
        >
          <Container
            header='Metadata'
          >
            <FormField
              label='Title'
              stretch={true}
            >
              <Input
                value={article.title}
                onChange={(event) => { setArticle({...article, title: event.detail.value}) }}
              />
            </FormField>
            <FormField
              label='Source'
              stretch={true}
            >
              <Input
                value={article.source}
                onChange={(event) => { setArticle({...article, source: event.detail.value}) }}
              />
            </FormField>
          </Container>
          <Container
            header='Text'
          >
            <Grid
              gridDefinition={[
                {colspan: 12},
                {colspan: 12},
                {colspan: 12},
              ]}
            >
              <FormField
                label='Low complexity'
                stretch={true}
              >
                <Grid
                  className='markdown-preview-grid'
                  gridDefinition={[
                    {colspan: 6},
                    {colspan: 6}
                  ]}
                >
                  <Textarea
                    className='markdown-editor'
                    value={article.text.low}
                    placeholder='Your text here'
                    onChange={(event) => { setArticle({...article, text:{...article.text, low: event.detail.value}})}}
                  />
                  <ReactMarkdown className='markdown-preview'>{article.text.low || '*Your text Here*'}</ReactMarkdown>
                </Grid>
              </FormField>
              <FormField
                label='Medium complexity'
                stretch={true}
              >
                <Grid
                  className='markdown-preview-grid'
                  gridDefinition={[
                    {colspan: 6},
                    {colspan: 6}
                  ]}
                >
                  <Textarea
                    className='markdown-editor'
                    value={article.text.med}
                    placeholder='Your text here'
                    onChange={(event) => { setArticle({...article, text:{...article.text, med: event.detail.value}})}}
                  />
                  <ReactMarkdown className='markdown-preview'>{article.text.med || '*Your text Here*'}</ReactMarkdown>
                </Grid>
              </FormField>
              <FormField
                label='High complexity'
                stretch={true}
              >
                <Grid
                  className='markdown-preview-grid'
                  gridDefinition={[
                    {colspan: 6},
                    {colspan: 6}
                  ]}
                >
                  <Textarea
                    className='markdown-editor'
                    value={article.text.high}
                    placeholder='Your text here'
                    onChange={(event) => { setArticle({...article, text:{...article.text, high: event.detail.value}})}}
                  />
                  <ReactMarkdown className='markdown-preview'>{article.text.high || '*Your text Here*'}</ReactMarkdown>
                </Grid>
              </FormField>
            </Grid>
          </Container>
        </Grid>
      }
    />
  );
}
