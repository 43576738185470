import './App.css';
import logo from './dodo_1.svg';
import { Route, Routes } from 'react-router';
import LandingPage from './landing/LandingPage';
import ArticlePage from './article/ArticlePage';
import AdminPage from './admin/AdminPage';
import ArticleEditPage from './article/edit/ArticleEditPage';

export default function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
      </header>
      <header className='App-header App-header-float'>
        <img src={logo} className='App-logo' alt='logo' />
      </header>
      <Routes>
        <Route exact path='/admin' element={<AdminPage />} />
        <Route exact path='/landing' element={<LandingPage />} />
        <Route exact path='/article/:id' element={<ArticlePage />}/>
        <Route exact path='/article/:id/edit' element={<ArticleEditPage />}/>
        <Route path='*' element={<LandingPage />} />
      </Routes>
    </div>
  );
}