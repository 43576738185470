import Amplify, { API } from 'aws-amplify';
var needs_config = true;

export function config() {
  Amplify.configure({
    API: {
        endpoints: [
            {
                name: 'userApi',
                endpoint: "https://3n0ef41dai.execute-api.us-east-1.amazonaws.com/prod/"
            },
            {
                name: 'adminApi',
                endpoint: "https://api.my-custom-cloudfront-domain.com/",

            }
        ]
    }
  });

  needs_config = false;
}

function getData(path, query, headers, apiName) {
  const myInit = {
    headers: {...headers, Authorization: '93c3609454e544138891d69144784552'}, // TODO: add API key, and session id
    queryStringParameters: query,
  };

  return API.get(apiName, path, myInit);
}

export async function get({path, query, headers, apiName}) {
  if (needs_config) config();
  return await getData(path, query || {}, headers || {}, apiName || 'userApi');
}