import { SideNavigation } from '@awsui/components-react';
import { useEffect, useState } from 'react';

export default function Nav() {
  // variables
  const [path, setPath] = useState('');

  useEffect(() => {
    setPath(document.location.hash);
  }, []);

  return (
    <SideNavigation
      header={{
        href: '#/',
        text: 'Articulation',
      }}
      items={[
        {
          type: 'link',
          href: '#/article/test_article',
          text: 'Sample article'
        },
      ]}
      activeHref={path}
    />
  )
}