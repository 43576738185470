import './LandingPage.css';
import { AppLayout } from '@awsui/components-react';
import Nav from '../common/Nav';

export default function LandingPage() {
  return (
    <AppLayout
      headerSelector='.App-header'
      toolsHide={true}
      navigation={<Nav/>}
      content={
        <h1>Welcome to articulation!</h1>
      }
    />
  );
}
