import './ArticlePage.css';
import { get } from '../utils/API';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactMarkdown from 'react-markdown';
import { AppLayout, BreadcrumbGroup, Container, ExpandableSection, FormField, HelpPanel, SegmentedControl, Spinner } from '@awsui/components-react';
import Nav from '../common/Nav';

export default function ArticlePage() {
  // variables
  const [article, setArticle] = useState({text:{}});
  const [complexity, setComplexity] = useState('low');
  const [loading, setLoading] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    const getArticle = async () => {
      let res = await get({
        path: 'articles/test_article',
      });

      setArticle(res);
      setLoading(false);
    }

    getArticle();
  }, []);

  return (
    <AppLayout
      headerSelector='.App-header'
      navigation={<Nav/>}
      navigationOpen={navOpen}
      onNavigationChange={(event) => { setNavOpen(!navOpen) }}
      contentHeader={
        <BreadcrumbGroup
          items={[
            {text:'Articles', href: '#/articles'},
            {text:article.title || id, href: `#/article/${id}`},
          ]}
        />
      }
      content={
        <>
        {
          loading ?
            <Spinner size='large' />
            :
            <div>
              <h1>{article.title}</h1>
              <a href={article.source}>Source</a>
              <FormField
                label='Complexity'
              >
                <SegmentedControl
                  selectedId={complexity}
                  options={[
                    {id: 'low', text: 'TL;DR'},
                    {id: 'med', text: 'Laymen'},
                    {id: 'high', text: 'Scientist'},
                  ]}
                  onChange={(event)=>{setComplexity(event.detail.selectedId)}}
                />
              </FormField>
              <Container
                className='article-text-container'
              >
                <ReactMarkdown>{article.text[complexity]}</ReactMarkdown>
              </Container>
            </div>
        }
        </>
      }
      tools={
        <HelpPanel
          header='More info'
        >
          <h2>Terminology</h2>
          <ExpandableSection
            header='Blockchain'
          >
            A method for distrubutively verifying a ledger of information.
          </ExpandableSection>
          <ExpandableSection
            header='Inanity'
          >
            Words, words, words...
          </ExpandableSection>
        </HelpPanel>
      }
    />
  );
}
